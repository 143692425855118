.hamburger-menu-wrapper {
    visibility: hidden;

    .ui.dimmer {
        padding-top: 0px;
    }

    .hamburger-menu-container {
        color: black;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
        width: 275px !important;
        height: 100%;
        right: 0px;
        top: 0px;
        border-radius: 0px !important;

        > .content {
            height: 100% !important;
            padding: 0px !important;
            background-color: $slide-menu-background-color !important;
            border-radius: 0px !important;

            > div {
                height: 100%;

                .slide-menu {
                    display: block;
                    top: -9px;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .ui.modal.hamburger-menu-container {
        > .content {
            padding: 0px !important;
        }
    }
}
