.card-header-text {
    overflow-wrap: break-word;
}

.editable-card-header-container {
    display: flex;
    align-items: center;
    position: relative;
    flex-grow: 1;
}

.editable-card-header {
    width: 100%;
    border-color: $border-color;
    border-radius: $border-radius;
    padding: 8px;
    color: $primary-text-color;
    font-weight: bold;
    font-size: 16px;
    margin-right: 10px;
    box-sizing: border-box;
    resize: none;
}

.card-header-modified {
    background-color: #f0e68c;
}

.card-header-clearable {
    padding-right: 28px;
}

.card-header-clear-button {
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
