﻿/*Global styling*/
// Remove spin button from input (number)
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

//IE and Edge will have an "x" on inputs, but we take care of this ourselves across all browser so turn this broswer-specific feature off
input[type="text"]::-ms-clear {
    display: none;
}
// -----------------

body {
    margin: 0;
    padding: 0;
    font-family: $font-family;
    font-size: 1em;
    background-color: $page-background-color;
    color: $primary-text-color;
}

.dashboard {
    max-width: 1619.5px;
    margin: auto !important;
    min-height: 100%;
}

.layout-grid {
    margin: auto !important;
}

#root {
    height: 100%;
}

.layout-wrapper {
    min-height: 100%;
    margin-top: $content-top-margin;

    .ui.menu {
        position: relative;
        z-index: 100;
    }

    .ui.dashboard {
        position: relative;
        z-index: 5;
    }
    // in the monitors, a dropdown can overlap a tabbed menu where we show results
    // the default in semantic is z-index 10
    .ui.form .field > .selection.dropdown.active {
        z-index: 105;
    }

    @media screen and (max-width: 991px) {
        .ui.basic.very.padded.segment {
            padding: 14px 0px 14px 0px;
        }

        .ui.dashboard {
            margin: 0px !important;
        }
    }

    @media only screen and (max-width: 767px) {
        .ui.table:not(.unstackable) th:first-child,
        .ui.table:not(.unstackable) td:first-child {
            font-weight: normal;
        }
    }
}

.body-segment {
    filter: drop-shadow(0px 0px 20px rgb(240, 238, 238)) !important;
}

.header-wrapper {
    justify-self: stretch;
    background-color: $header-background-color;
    text-transform: uppercase;
    padding: 19px;
    height: 60px;

    .navigation-links {
        font-size: 1em;
        float: right;

        .navigation-link {
            padding: 20px;

            a {
                color: white;
                font-weight: 500;
            }
        }
    }
}

.header-logo {
    margin-left: 1.5em;
    height: 60px;
    padding-top: 12px;
    min-width: 150px;

    > img {
        height: 36px !important;
    }
}

.footer-wrapper {
    background-color: $footer-background-color;
    color: white;
    justify-self: stretch;

    .footer-content {
        font-size: 1em;
        text-transform: uppercase;
    }
}

/*table additional style*/
.table-header-subtitle-font {
    font-size: 0.8em;
}

.datePickerContainer {
    float: left;
    margin-left: 13px;
}

.metricConfigurationArea {
    float: right;
}

@media only screen and (max-width: 1110px) {
    .metricConfigurationArea {
        float: left;
        width: 100%;
        margin-top: 28px;
        margin-left: 0px;
    }
}

@media only screen and (max-width: 991px) {
    .datePickerContainer,
    .metricConfigurationModal {
        float: left;
        width: 100%;
        margin-top: 28px;
        margin-left: 0px;
    }
}

/*rdatez calendar picker style*/
.rdatez-input-custom {
    color: $primary-text-color;
    cursor: pointer;
    width: 200px;
    padding-left: 20px;
    height: 40px;
    font-size: 1.5em;
    font-family: sans-serif;
    border: none;
    border-radius: 1rem;
    box-shadow: 0px 1px 3px 0px #d4d4d5, 0px 0px 0px 1px #d4d4d5;
}

.rdatez-picker-custom {
    div.rdatez-picker,
    div.rdatez-picker > * {
        border-radius: 1rem;

        .rdatez-btn {
            border-radius: 1rem;
        }

        .rdatez-year,
        .rdatez-month {
            color: $primary-text-color;
        }

        //this styling makes all the days in the past colored in a custom color
        //, while still allowing the non-selectable future days to be grayed out
        .rdatez-day:not(.selected-day) {
            color: $primary-text-color !important;
        }

        &.disallow-future .rdatez-day:not(.past-day):not(.today) {
            color: rgba(0, 0, 0, 0.1) !important;
        }

        .selected-day {
            background: $delaget-blue !important;
            color: white !important;
        }

        .start-of-period {
            border-left: solid;
        }

        .period-counter {
            font-size: 75%;
            position: absolute;
            top: 1px;
            left: 3px;
        }

        .delivery-provider-labels {
            font-size: 75%;
            font-weight: bold;
            position: absolute;
            top: 1px;
            left: 38px;
        }
    }
}

.rdatez-picker-custom-disabled {
    pointer-events: none !important;

    div.rdatez {
        input.rdatez-input-custom {
            color: rgb(125, 127, 133) !important;
        }

        svg {
            opacity: 0.4;
        }
    }
}

.rdatez-picker {
    top: 39px !important;
}

.rdatez-day.past-day {
    color: #666 !important;
}

.no-margin {
    margin: 0px;
}

.inline-margin {
    margin: 0 1em 0 1em;
}

.inline-margin-small {
    margin: 0 0.5em 0 0.5em;
}

.table-grid-icon {
    opacity: 0.9 !important;
}

.column-150 {
    width: 150px !important;
}

.divider-no-margin-top {
    margin-top: 0px !important;
}

.padding-left-15px {
    padding-left: 15px !important;
}

.padding-left-30px {
    padding-left: 30px !important;
}

.padding-bottom-0px {
    padding-bottom: 0px !important;
}

.padding-row-14px {
    padding: 0px 14px !important;
}

.btn:hover {
    border: 1px solid $delaget-orange !important;
    background-color: $delaget-orange !important;
    color: white !important;
}

.btn-blue {
    border: 1px solid $delaget-blue !important;
    background-color: white !important;
    color: $delaget-blue !important;
}

.btn-secondary-color {
    background-color: $delaget-secondary-color !important;
    color: white !important;
}

.btn-red {
    border: 1px solid #d11515 !important;
    background-color: white !important;
    color: #d11515 !important;
}

.btn-green {
    border: 1px solid #1b8373 !important;
    background-color: #1b8373 !important;
    color: white !important;
}

.btn-orange {
    border: 1px solid $delaget-orange !important;
    background-color: $delaget-orange !important;
    color: white !important;
}

.btn-orange:hover {
    border: 1px solid $delaget-orange !important;
    background-color: white !important;
    color: $delaget-orange !important;
}

.btn-blue-solid {
    background-color: $delaget-blue !important;
    color: white !important;
}

.btn-blue-solid:hover {
    border: 0px !important;
}

.ui.basic.buttons.button-group-active .active.button,
.ui.basic.active.button.button-group-active {
    background: $delaget-blue !important;
    color: white !important;
}

ul.dashed {
    list-style-type: none;
}

ul.dashed > li:before {
    content: "\2014";
    position: absolute;
    margin-left: -1.2em;
}

li.dashed {
    margin-bottom: 7px;
}

li.dashed:last-child {
    margin-bottom: 0px;
}

.custom-date-range-picker-apply {
    display: inline-block;
}

@media only screen and (max-width: 991px) {
    .custom-date-range-picker-apply {
        margin-top: 7px;
    }
}

.overflow-auto {
    overflow: auto;
}

.footer {
    border: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.configuration-module {
    display: inline-block !important;
    padding: 0px 14px !important;
}
