.modal-margin-adjustment {
    margin: 0 0 1em 0 !important;
}

.create-task-modal-box {
    min-width: 450px;
    max-width: 600px;
}

.modal-locations-list {
    padding: 0 0 0.28571429rem 0;
}

.modal-icon {
    color: #4fa3cc;
}

.modal-icon-submit {
    color: #1b8373;
}

.modal-icon-cancel {
    color: #e56464;
    z-index: 999;
}

.popup-icon-success {
    color: #1b8373;
}

.popup-icon-failure {
    color: #d11515;
}

.comments-view {
    max-width: inherit !important;
}

.add-comment-button {
    background-color: $delaget-secondary-color !important;
}

.comment-text-area {
    :first-child {
        height: 60px !important;
    }
}
