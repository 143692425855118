.monitor-value {
    font-size: 1.2rem !important;
}

@media only screen and (max-width: 768px) {
    .monitorTopButtons {
        margin-top: 20px !important;
        text-align: left !important;
    }
}
