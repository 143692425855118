.ui.selection.delaget-ui-header-dropdown {
    background: white;
    color: $delaget-blue;
    z-index: 99;
    font-size: 1.04em;
    border-color: $border-color;
    &.active {
        border-color: $border-color;
        z-index: 199;
        &:hover {
            border-color: $border-color;
            .menu {
                border-color: $border-color;
                &:hover {
                    border-color: $border-color;
                }
            }
        }
        .menu {
            max-height: 30rem;
            border-color: $border-color;
            &:hover {
                border-color: $border-color;
            }
            z-index: 199;
        }
    }
    > .text {
        font-weight: bolder !important;
        color: $primary-text-color !important;
    }
}

.rc-tree-select-tree li span.rc-tree-select-tree-checkbox {
    margin-bottom: 5px;
}
.rc-tree-select-selection__choice__content:hover {
    margin: 0.14285714rem 0.28571429rem 0.14285714rem 0em !important;
}

.delaget-dropdown-detect-search {
    width: 100%;
    min-width: inherit;
    min-height: 38px;
    font-size: 14px;
    font-weight: normal;
    color: #232630;
    cursor: pointer;
    border-color: rgba(34, 36, 38, 0.15);
    outline-style: none;
    overflow: auto;
    max-height: 160px;

    &.rc-tree-select-enabled {
        :hover {
            border-color: rgb(178, 179, 179) !important;
            outline: none;
            box-shadow: none;
        }

        .rc-tree-select-selection {
            border-color: inherit;
            outline: none;
            box-shadow: none;
            min-height: 38px;
        }
    }

    &.rc-tree-select-disabled {
        .rc-tree-select-selection {
            border-color: inherit;
            outline: none;
            box-shadow: none;
            min-height: 38px;
            cursor: default;
            color: rgb(125, 127, 133);
        }
    }

    .rc-tree-select-selection {
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-radius: $border-radius;
        background-color: rgb(255, 255, 255);
        .rc-tree-select-selection__choice {
            display: inline-block;
            line-height: 1;
            vertical-align: top;
            margin: 0.14285714rem 0.28571429rem 0.14285714rem 0em;
            background-color: #e8e8e8;
            background-image: none;
            padding: 0.35714286em 0.78571429em;
            color: rgba(0, 0, 0, 0.6);
            text-transform: none;
            font-weight: bold;
            border: 0px solid transparent;
            border-radius: 1rem;
            transition: background 0.1s ease;
            &:hover {
                margin: 0.14285714rem 0.28571429rem 0.14285714rem 0em;
            }
        }
        .rc-tree-select-selection__choice {
            margin: 0.14285714rem 0.28571429rem 0.14285714rem 0em;
            &:hover {
                margin: 0.14285714rem 0.28571429rem 0.14285714rem 0em;
            }
        }
        .rc-tree-select-selection__choice__content {
            margin: 0.14285714rem 0.28571429rem 0.14285714rem 0em;
        }
        .rc-tree-select-selection__choice__remove {
            display: none;
        }
        .rc-tree-select-arrow {
            height: 36px;
            border-color: rgba(79, 81, 89, 0.9) transparent transparent transparent !important;
            margin-right: 7px;

            &:after {
                border-color: rgba(79, 81, 89, 0.9) transparent transparent
                    transparent !important;
                border-width: 4px 4px 0 4px;
            }

            // Hides the accidental second arrow displayed in the Detector Search page
            i {
                visibility: hidden;
            }
        }

        .rc-tree-select-selection__rendered {
            min-height: 36px;

            > span {
                padding-left: 4px;
                padding-top: 4px;
                display: inline-block;
            }

            .rc-tree-select-selection__placeholder {
                opacity: 0.2;
            }
        }
    }

    .rc-tree-select-search__field {
        visibility: hidden;
    }
}

.delaget-dropdown {
    width: 290px;
    min-height: 40px;
    font-size: 1.2em;
    font-weight: bold;
    color: $delaget-blue;
    cursor: pointer;
    border-color: $border-color;
    outline-style: none;

    &.rc-tree-select-enabled {
        .rc-tree-select-selection {
            border-color: inherit;
        }
    }

    .rc-tree-select-selection {
        height: auto;
        box-shadow: $box-shadow !important;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        background-color: $header-background-color;

        .rc-tree-select-arrow {
            min-height: 40px;
            color: white;

            &:after {
                border-style: none;
            }
        }

        .rc-tree-select-selection__rendered {
            min-height: 40px;

            > span {
                padding-top: 0.3em;
                display: inline-block;

                > a {
                    color: rgb(17, 36, 89) !important;
                }
            }

            .rc-tree-select-selection-selected-value{
                max-width: 100%;
            }
        }
    }
}

.delaget-dropdown-list {
    min-width: 10em;
    font-size: 1.2em;
    color: $link-font-color;

    border: 1px solid $border-color;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    overflow-x: hidden;
    overflow-y: auto;
    backface-visibility: hidden;
    -webkit-overflow-scrolling: touch;
    max-height: 40vh;
    margin-top: $content-top-margin;

    z-index: 1001;

    .rc-tree-select-dropdown-search {
        padding: 0.8em;
        border-bottom: 1px solid $border-color;

        .rc-tree-select-search__field {
            border: 1px solid $border-color;
            border-radius: $border-radius;
        }
    }

    .rc-tree-select-tree {
        li {
            padding: 0.5em 0.5em 0em 0.5em;

            .rc-tree-select-tree-node-selected {
                font-weight: bolder;
                background-color: inherit;
                border: 0px;
                color: $link-selected-font-color;
            }

            .rc-tree-select-tree-node-content-wrapper:hover {
                font-weight: bolder;
                cursor: pointer;
            }

            .rc-tree-select-tree-switcher {
                margin-right: 5px;
                height: 21px;
            }

            &.filter-node > .rc-tree-select-tree-node-content-wrapper {
                color: $link-font-color !important;
            }
        }
    }
}
