.subRow {
    color: #6d6d75;
}

.boldRow {
    font-weight: 600 !important;
}

.clickable-row {
    cursor: pointer;
}

.clickable-value {
    color: $link-font-color;
    &:hover {
        color: $link-selected-font-color !important;
    }
    cursor: pointer;
}

.clickable-value-bold {
    color: $link-font-color;
    &:hover {
        color: $link-selected-font-color !important;
    }
    cursor: pointer;
    font-weight: bolder;
}

.metric-violating-threshold {
    color: $metric-violating-threshold-color;
    font-weight: bold;
}

.non-clickable-row-arrow {
    &:hover {
        background-color: white !important;
    }
    cursor: default !important;
}

.non-clickable-row-text {
    &:hover {
        background-color: white !important;
    }
    cursor: text !important;
}

.pager {
    height: 36px !important;
    box-shadow: none !important;
    z-index: 5 !important;

    .active.item {
        background: $delaget-blue !important;
        color: white !important;
    }
}

@media only screen and (max-width: 991px) {
    .xtdContainer,
    .businessUnitAggregationPickerContainer {
        margin-top: 20px !important;
        text-align: left !important;
    }
}

.mobile-screen-value-metric-name {
    width: 65%;
    text-align: left;
    display: inline-block;
    margin: 0px;
    overflow: hidden;
}

.mobile-screen-value-metric-value {
    width: 35%;
    text-align: left;
    display: inline-block;
    margin: 0px;
}

.custom-date-range-label {
    display: block;
    float: left;
    margin-bottom: 6px;
}

.export-container {
    display: block;
    float: right;
    margin-bottom: 6px;
}

.table-wrapper {
    width: inherit;
}

.overflow-x-scroll {
    overflow-x: auto;
}

.scrollable-table {
    tbody {
        display: block;
        height: 450px;
        overflow: auto;
    }

    thead,
    tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }
}

.help-icon-table-header-position {
    position: absolute;
    top: 2px;
    right: -2px;
}

.filterIcon {
    color: $delaget-secondary-color;
    &:hover {
        color: $delaget-orange;
    }
}

.IconButton {
    width: 100%;
    height: auto;
    margin: 10px 0 10px 0 !important;
    line-height: 3em !important;

    .active {
        color: $delaget-orange;
        float: center;
        padding-left: 20px;
    }

    .inActive {
        color: $delaget-secondary-color;
        float: center;
        padding-left: 20px;
    }
}

//Semantic UI has the concept of an icon being ciruclar which gives it many css rules
//but the color of the circle is always a faint grey.  Make it the same color as the icon iteslf
i.circular.icon.filterIcon {
    margin-bottom: 5px;
    box-shadow: 0em 0em 0em 0.1em $delaget-secondary-color inset;
    &:hover {
        box-shadow: 0em 0em 0em 0.1em $delaget-orange inset;
    }
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 10vw;
}
