#mobile-nav-icon,
.mobile-nav-option {
    display: none;
}

.desktop-nav-option {
    display: block !important;
}

//important for the Detect Dropdown that shows when a client has multiple concepts with Detect
.delaget-header.ui.secondary.menu .menu > .item,
.delaget-header.ui.menu .ui.dropdown .menu > .item:hover {
    color: $primary-text-color !important;
    background-color: $header-background-color !important;

    &:hover {
        color: $delaget-orange !important;
    }
}

// styling of the header menu for mobile view
.delaget-header.ui.inverted.secondary.stackable.attached > .item,
.delaget-header.ui.inverted.secondary.stackable.attached > .item:hover {
    color: $primary-text-color !important;
    background-color: $header-background-color !important;

    &:hover {
        color: $delaget-orange !important;
    }
}

.delaget-header.ui.secondary.menu a.item:hover {
    color: $delaget-orange;
    background-color: $header-background-color;
}

@media only screen and (max-width: 991px) {
    #mobile-nav-icon {
        display: block;
    }

    .mobile-nav-option {
        display: block;
    }

    .desktop-nav-option {
        display: none !important;
    }
}

.ui.modal.mobile-nav-options {
    > .content {
        padding: 0px !important;
    }
}
