// This makes placeholder in input to be more visible
.input-placeholder {
    ::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: rgb(138, 138, 138) !important;
        opacity: 0.8 !important;
    }
    :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: rgb(138, 138, 138) !important;
        opacity: 0.8 !important;
    }
    ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: rgb(138, 138, 138) !important;
        opacity: 0.8 !important;
    }
    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgb(138, 138, 138) !important;
        opacity: 0.8 !important;
    }
    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: rgb(138, 138, 138) !important;
        opacity: 0.8 !important;
    }

    ::placeholder {
        /* Most modern browsers support this now. */
        color: rgb(138, 138, 138) !important;
        opacity: 0.8 !important;
    } /* Chrome, Firefox, Opera, Safari 10.1+ */
}
