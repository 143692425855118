.pageConfiguration {
    .draggableSegment {
        margin: 10px 0px !important;
        padding: 10px !important;
        border-radius: $border-radius-medium !important;
    }
    .paddedText {
        padding-left: 10px !important;
    }
}

#dropdown-item-no-adding {
    padding: 0px !important;
}

.role-access-picker-button {
    padding: 11px 5px 11px 5px !important;
}

@media only screen and (min-width: 992px) {
    .role-access-picker-button-all {
        width: 24%;
    }
    .role-access-picker-button-admin {
        width: 34%;
    }
    .role-access-picker-button-homeoffice {
        width: 42%;
    }
}

@media only screen and (max-width: 991px) {
    .role-access-picker-button-all {
        width: 33%;
    }
    .role-access-picker-button-admin {
        width: 33%;
    }
    .role-access-picker-button-homeoffice {
        width: 34%;
    }
}
