﻿/*------------------------------------*\
    $VARIABLES
\*------------------------------------*/
$font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
$primary-text-color: #112459;

$page-background-color: #d4f2f7;
$header-background-color: white;
$footer-background-color: white;

$slide-menu-background-color: white;
$border-color: #d4d4d5;
$border-radius: 1rem;
$border-radius-medium: 0.5rem;
$box-shadow: 0px 1px 3px 0 #d4d4d5;

$link-font-color: #16729b;
$link-selected-font-color: #f47d46;

$delaget-blue: #112459;
$delaget-orange: #f47d46;
$delaget-secondary-color: #848cc4;

$metric-violating-threshold-color: #e56464;

//Top margin for the main content and modals.  Used to start content after the sticky header
$content-top-margin: 60px !important;
