.card {
    padding-right: 0rem !important;

    .extra {
        margin: 0 0.5rem 0 0.5rem !important;
    }

    .help-icon-position {
        position: relative;
        top: -5px;
    }

    .description {
        margin: 0.5rem 0 0 0 !important;
    }
}

.content-box-container {
    display: inline-block;
    position: relative;
    width: 100%;
    border-bottom: 1px solid $border-color;
    margin: 1rem 0 0 0 !important;
}

.content-box {
    display: inline-block;

    .ui.statistic:not(.red) > .value {
        color: $primary-text-color;
    }

    &.xtd {
        width: 55px;

        .ui.statistic > .value {
            text-transform: none;
        }
    }
}

.font-bold {
    :first-child {
        font-weight: bold !important;
    }
}

.font-normal {
    :first-child {
        font-weight: normal !important;
    }
}

.statistic-margin {
    margin: 5px 10px 5px 0px !important;
}

.statistic-margin-daily {
    margin: 5px 10px 10px 0px !important;
}

.text-align-right {
    text-align: right;
}

.padding-left-10px {
    padding-left: 10px !important;
}

.colored-toggle {
    input ~ label {
        &:before {
            background-color: $delaget-secondary-color !important;
        }
    }
}

.partner-button {
    margin-top: 5px !important;
    padding: 5px !important;
    transform: translateX(25%);

    .partner-logo {
        width: 120px;
    }

    .sign-in-icon {
        margin: 0px 0px 3px 3px !important;
    }
}
